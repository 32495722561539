import dayjs from 'dayjs';
import sum from 'lodash/sum';
import { Duration } from 'dayjs/plugin/duration';
import { blue } from 'easycolors/build/tailwind';
import { tailwind } from 'easycolors';
import { round } from 'lodash';
import { t } from 'i18next';
import { getLicenseCategoryColor } from '../../utilities/colorForLicenseCategory';
import { formatNumberWithSign } from '../../utilities/numberWithSign';
import {
  Color,
  CreateTimesheetDto,
  UpdateTimesheetDto,
  WorkEvent,
} from '../../typings/backend-types';
import authenticatedPost from '../../data/authenticatedPost';
import { HttpEndpoints } from '../../data/httpEndpoints';

export function getLicenseCategoryColorHex(lc: string): string {
  const color = getLicenseCategoryColor(lc);
  if (color === Color.Blue) return blue[400];
  return tailwind[color][500];
}

export const NO_BILLING_TYPE_TAG = 'no-tag';

export function getBillingTypeTagColor(billingTypeTagId: string): string {
  return tailwind[getBillingTypeTagTailwindColor(billingTypeTagId)][500];
}
export function getBillingTypeTagTailwindColor(
  billingTypeTagId: string,
): Color {
  if (billingTypeTagId === NO_BILLING_TYPE_TAG) return Color.Gray;

  const keys = [
    Color.Purple,
    Color.Yellow,
    Color.Blue,
    Color.Orange,
    Color.Green,
  ];
  const index = (billingTypeTagId.codePointAt(0) || 0) % keys.length;
  return keys[index];
}

const timeTrackingInLessons =
  process.env.NEXT_PUBLIC_TIME_TRACKING_IN_LESSONS === 'true';

export const weeklyTimeTracking =
  process.env.NEXT_PUBLIC_WEEKLY_TIME_TRACKING === 'true';

export function useTimeTrackingUnit(): { timeTrackingInLessons: boolean } {
  return { timeTrackingInLessons };
}

export function useWeeklyTimeTracking(): { weeklyTimeTracking: boolean } {
  return { weeklyTimeTracking };
}

export function durationToReadable(
  durationHours: number,
  lessonDurationInMinutes: number,
): string {
  const duration = dayjs.duration(durationHours, 'h');
  if (timeTrackingInLessons) {
    return (
      round(duration.asMinutes() / lessonDurationInMinutes, 2) +
      ' ' +
      t('eventsList.lessons', {
        count: duration.asMinutes() / lessonDurationInMinutes,
      })
    );
  }
  return `${Math.floor(duration.asHours())}h ${duration.minutes()}m`;
}

export function durationTotalsFromCategories(
  eventsGroupedByCategories: Record<string, WorkEvent[]>,
): Duration[] {
  const millisecondsPerMinute = 60000;

  return Object.values(eventsGroupedByCategories).map((events) =>
    dayjs.duration(
      sum(
        events.map(
          (e) =>
            dayjs(e.end_time).diff(e.start_time) +
            (e.billingType?.shadow_time ?? 0) * millisecondsPerMinute,
        ),
      ),
    ),
  );
}

export function createOrUpdateTimesheet(
  timesheetId: string | undefined,
  userId: string,
  body: CreateTimesheetDto | UpdateTimesheetDto,
): Promise<Response> {
  if (timesheetId) {
    return authenticatedPost(
      HttpEndpoints.TimesheetEndpoints.patchTimesheet(timesheetId, userId),
      body,
      'PATCH',
    );
  }
  return authenticatedPost(
    HttpEndpoints.TimesheetEndpoints.postTimesheet(userId),
    body,
  );
}

export function parseHours(
  hours: number | undefined,
  lessonDurationInMinutes: number,
): string {
  if (hours == undefined) return '';
  if (timeTrackingInLessons) {
    return String(round((hours * 60) / lessonDurationInMinutes, 2));
  }
  return String(round(hours, 2));
}

export function parseHoursWithSign(
  hours: number,
  lessonDurationInMinutes: number,
): string {
  if (timeTrackingInLessons) {
    return formatNumberWithSign(
      round((hours * 60) / lessonDurationInMinutes, 2),
    );
  }
  return formatNumberWithSign(hours);
}

export function getFirstMondayOfMonth(
  year: number,
  monthIndex: number,
): dayjs.Dayjs {
  const firstDayOfMonth = dayjs().year(year).month(monthIndex).date(1);
  return firstDayOfMonth.day() === 1 ? firstDayOfMonth : firstDayOfMonth.day(8);
}
