import { useAuth0 } from '@auth0/auth0-react';
import React, { useEffect } from 'react';
import SignInButton from '../components/SidebarLayout/SignInButton';
import { TokenGeneratorSingleton } from './TokenGeneratorSingleton';
import { LoadingIndicator } from '@components/Common/LoadingIndicator';
import { ErrorType } from 'utilities/errors';
import { ErrorScreen } from '@components/InfoScreens/ErrorScreen';
import { useTranslation } from 'react-i18next';
import { setContext } from '@sentry/nextjs';

/**
 * This component gives us the chance to show a loading indicator or something
 * while auth0 is being initialized.
 */
function Auth0InitializationWrapper({ children }) {
  const { isLoading, error, isAuthenticated, getAccessTokenSilently, user } =
    useAuth0();
  const { t } = useTranslation('translation', { keyPrefix: 'login' });

  useEffect(() => {
    TokenGeneratorSingleton.getInstance().setTokenGenerator(
      getAccessTokenSilently,
    );
  }, [getAccessTokenSilently]);

  useEffect(() => {
    setContext('user', user);
  }, [user]);

  if (isLoading) {
    return (
      <div className="grid place-items-center h-screen">
        <LoadingIndicator />
      </div>
    );
  }
  if (error) {
    return (
      <ErrorScreen
        type={ErrorType.AUTH0_INITIALIZATION}
        customMessage={error.message}
        customTitle={error.name}
        logoutOnReturn
      />
    );
  }
  if (!isAuthenticated && !isLoading) {
    // If this is called too often/on logged in users, I feel like we have a problem elsewhere
    // localStorage.clear();
    return (
      <div className="bg-white min-h-screen px-4 py-16 sm:px-6 sm:py-24 grid place-items-center lg:px-8">
        <div className="max-w-max mx-auto">
          <main>
            <div className="flex">
              <div className="shrink-0">
                <img
                  src="/logo-full-size.png"
                  width={80}
                  className="inline-block"
                />
              </div>
              <div className="pl-4">
                <div>
                  <h1 className="text-4xl font-bold text-gray-900 tracking-tight sm:text-6xl flex items-center">
                    AdminDrive
                  </h1>
                  <p className="mt-1 text-base text-gray-500">
                    {t('pleaseLogin')}
                  </p>
                </div>
                <div className="mt-10 flex space-x-3 sm:border-l sm:border-transparent">
                  <SignInButton />
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    );
  }
  return <>{children}</>;
}
export default Auth0InitializationWrapper;
